import SVG from './SVG'

const Logo = props => (
  <SVG
    width="177"
    height="170"
    viewBox="0 0 177 170"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="177" height="170" rx="40" fill="#00539B" />
    <path
      d="M58.9471 107.902C67.0021 111.548 74.3103 112.693 81.2185 112.693C89.4869 112.693 94.4746 109.446 94.4746 104.256C94.4746 99.066 90.2604 96.5908 82.3654 94.4882C66.0419 90.2831 58.9471 81.8461 58.9471 67.4741C58.9471 47.3532 76.2307 37 95.0614 37C105.624 37 112.932 38.73 118.293 41.0189L116.746 61.7253C110.985 58.6645 104.85 57.1209 97.9153 57.1209C90.2337 57.1209 84.6592 60.3679 84.6592 66.1167C84.6592 71.28 88.6867 74.3673 95.0347 75.8844C113.092 80.0896 120 88.9257 120 102.712C120 121.502 102.716 133 81.1918 133C73.1367 133 63.9081 131.27 57 128.023L58.9204 107.902H58.9471Z"
      fill="white"
    />
    <g clipPath="url(#clip0_6792_195808)">
      <path
        d="M135 118.489C135 126.491 128.284 132.977 120 132.977C111.716 132.977 105 126.491 105 118.489C105 110.487 111.716 104 120 104C128.284 104 135 110.487 135 118.489Z"
        fill="#FDC043"
      />
      <path
        d="M133 118.489C133 125.321 127.246 130.977 120 130.977C112.754 130.977 107 125.321 107 118.489C107 111.656 112.754 106 120 106C127.246 106 133 111.656 133 118.489Z"
        stroke="#00539B"
        strokeWidth="4"
      />
      <path
        d="M132.178 118.489C132.178 124.985 126.726 130.252 120 130.252C113.274 130.252 107.822 124.985 107.822 118.489C107.822 111.992 113.274 106.726 120 106.726C126.726 106.726 132.178 111.992 132.178 118.489Z"
        fill="#FFBF34"
      />
      <path
        d="M120 106.726C119.437 106.726 118.883 106.764 118.34 106.835C124.28 107.617 128.858 112.536 128.858 118.489C128.858 124.441 124.28 129.36 118.34 130.142C118.883 130.214 119.437 130.252 120 130.252C126.726 130.252 132.178 124.985 132.178 118.489C132.178 111.992 126.726 106.726 120 106.726Z"
        fill="#FFBF34"
      />
    </g>
    <defs>
      <clipPath id="clip0_6792_195808">
        <rect width="30" height="28.9773" fill="white" transform="translate(105 104)" />
      </clipPath>
    </defs>
  </SVG>
)

export default Logo
