import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import LoginForm from '@sholdi/ui/organisms/LoginForm'
import Image from '@sholdi/primitives/atoms/Image'
import Panel from '@sholdi/primitives/atoms/Panel'

function Login({ shop }) {
  const router = useRouter()
  return (
    <div className="min-h-full flex items-center justify-center py-8">
      <Panel className="py-4">
        <LoginForm
          router={router}
          logo={() => (
            <Image src={shop.logo} alt={shop.name} width={100} ar="custom" height={100} />
          )}
        />
      </Panel>
    </div>
  )
}

Login.propTypes = {
  shop: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    settings: PropTypes.arrayOf(
      PropTypes.shape({
        settings: PropTypes.object,
      }),
    ),
  }),
}

export default Login
