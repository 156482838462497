import * as yup from 'yup'

export const useGetValidationSchema = t =>
  yup.object().shape({
    email: yup
      .string()
      .required(t('formValidation.email'))
      .email(t('formValidation.invalidEmail')),
    password: yup.string().required(t('formValidation.password')),
  })

export const initial = {
  email: '',
  password: '',
  // rememberMe: false,
}
