import PropTypes from 'prop-types'
import { useField } from 'formik'

import Field from './index'

const FormikField = ({ name, onChange: onChangeProp, ...props }) => {
  const [input, meta] = useField(name)
  const { onChange: changeHandler, ...field } = input

  // eslint-disable-next-line no-unused-vars
  const { initialTouched, initialError, initialValue, ...rest } = meta

  // onChange if you need to do something else besides the form value change
  const onChange = e => {
    changeHandler(e)
    if (onChangeProp) {
      onChangeProp(e)
    }
  }

  return <Field onChange={onChange} {...field} {...rest} {...props} />
}

FormikField.displayName = 'FormikField'

FormikField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default FormikField
