import Head from 'next/head'
import PropTypes from 'prop-types'

import LoginPage from '../components/pageviews/Login'
import { withServerSideProps } from '../lib/withServerSideProps'

import SHOP_INTERFACE_QUERY from '@sholdi/graphql/queries/shop/shopInterface'

import initUrql from '@sholdi/shared/lib/urql/init-urql'
import getEnv from '@sholdi/shared/lib/env/getEnv'
import I18n from '@sholdi/providers/I18n'

import bs from '@sholdi/locales/bs.json'

function Login({ shop }) {
  return (
    <>
      <Head>
        <title>Prijava | {shop.name}</title>
      </Head>
      <I18n locale="BS" lngDict={bs}>
        <LoginPage shop={shop} />
      </I18n>
    </>
  )
}

export const getServerSideProps = withServerSideProps({
  getProps: async () => {
    const { client } = initUrql('marketplaceAdmin', {}, getEnv('SHOLDI_HASURA_SECRET'))

    const { data } = await client
      .query(SHOP_INTERFACE_QUERY, {
        shopId: getEnv('NEXT_PUBLIC_SHOLDI_STORE_ID'),
      })
      .toPromise()
    const { shopsByPk } = data || {}

    return {
      shop: shopsByPk,
    }
  },
})

Login.propTypes = {
  shop: PropTypes.shape({
    name: PropTypes.string,
  }),
}

export default Login
